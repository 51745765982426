/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconHeart extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "HeartIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' x="0px" y="0px" viewBox="0 0 15 15" xml:space="preserve"><path d="M13.91,6.75c-1.17,2.25-4.3,5.31-6.07,6.94c-0.1903,0.1718-0.4797,0.1718-0.67,0C5.39,12.06,2.26,9,1.09,6.75 C-1.48,1.8,5-1.5,7.5,3.45C10-1.5,16.48,1.8,13.91,6.75z" id="path2" stroke="#ffffff" stroke-opacity="1" fill="none" stroke-width="1" stroke-miterlimit="4" stroke-dasharray="none" stroke-linecap="butt" stroke-linejoin="round"></path></svg>
    `;
}
