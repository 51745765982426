/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconFeedback extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "FeedbackIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xml:space="preserve"><g><g><path fill="#231F20"  d="M34.233,47.42c-8.813,0-15.983-7.17-15.983-15.983c0-8.814,7.17-15.984,15.983-15.984 c8.814,0,15.984,7.17,15.984,15.984C50.217,40.25,43.047,47.42,34.233,47.42z M34.233,17.651 c-7.601,0-13.784,6.185-13.784,13.785S26.632,45.22,34.233,45.22s13.785-6.184,13.785-13.784S41.833,17.651,34.233,17.651z"></path></g><g id="XMLID_657_"><path fill="#231F20"  d="M33.646,34.478c-0.529,0-0.92-0.391-0.989-0.92l-0.229-1.978 c-0.092-0.621,0.276-1.081,0.896-1.149c2.162-0.207,3.357-1.035,3.357-2.483v-0.047c0-1.287-0.988-2.184-2.645-2.184 c-1.219,0-2.207,0.437-3.127,1.265c-0.23,0.184-0.529,0.321-0.851,0.321c-0.713,0-1.288-0.574-1.288-1.265 c0-0.345,0.138-0.713,0.46-0.988c1.219-1.15,2.76-1.909,4.875-1.909c3.219,0,5.403,1.794,5.403,4.668v0.046 c0,2.897-2.092,4.208-4.645,4.646l-0.092,1.058c-0.092,0.506-0.46,0.92-0.989,0.92H33.646z M33.646,36.157 c0.942,0,1.633,0.689,1.633,1.587v0.229c0,0.896-0.69,1.587-1.633,1.587s-1.633-0.69-1.633-1.587v-0.229 C32.013,36.846,32.704,36.157,33.646,36.157z"></path></g></g></svg>
    `;
}
