/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconDemographicCouple extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "DemographicCoupleIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M35.88,4.91a5.74,5.74,0,0,0-.77.05,5.64,5.64,0,0,0-4,2.54,5.65,5.65,0,0,0-9.37,4.95c.68,5,5.71,7.5,9,9.14a21.84,21.84,0,0,1,2.1,1.11l.36.23.28-.32A21.77,21.77,0,0,1,35.24,21c2.78-2.47,6.91-6.24,6.24-11.21a5.65,5.65,0,0,0-5.6-4.86" fill="#f886c1"></path><rect height="16.77" fill="#3e2723" transform="translate(-8.32 10.3) rotate(-12.1)" width="17.89" x="35.5" y="36.03"></rect><path d="M52.3,33c-1.79-8.35-20.85-1.22-19.68,4.22l1.64,7.65a10.06,10.06,0,1,0,19.68-4.22Z" fill="#ffcc80"></path><path d="M40.35,25.28C33.71,26.71,25.78,32,30,51.53l7.5,3-2.58-12L46.36,32l5.54,4.53,3,14.21,5.39-6.87c-.66-3.08-3.86-19.76-15.15-17.34l-1.56-2Z" fill="#795548"></path><rect height="17.89" fill="#c77600" transform="translate(-29.33 55.28) rotate(-77.48)" width="16.77" x="11.4" y="36.97"></rect><path d="M31.67,38.82C33.53,30.49,13.23,29,12,34.46l-1.7,7.64A10.06,10.06,0,1,0,30,46.46Z" fill="#ffcc80"></path><path d="M24,26.81C17.4,25.34,8,26.87,3.66,46.34l5.58,5.82,2.67-12,14.8-4.73,3.15,6.43L26.7,56l7.76-4C35.15,49,39.18,32.47,27.91,30l-.61-2.43Z" fill="#c77600"></path></g></svg>
    `;
}
