/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconEmail extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "EmailIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="${this.props.viewBox || "0 0 64 64"}" enable-background="new 0 0 64 64"  xml:space="preserve"><g><path fill="#231F20"  d="M45.381,45.698H19.086c-1.964,0-3.562-1.598-3.562-3.562V25.841c0-1.707,1.389-3.095,3.095-3.095 h26.762c1.707,0,3.095,1.388,3.095,3.095v16.762C48.476,44.311,47.088,45.698,45.381,45.698z M18.619,24.947 c-0.494,0-0.895,0.401-0.895,0.895v16.295c0,0.751,0.611,1.362,1.362,1.362h26.295c0.493,0,0.896-0.402,0.896-0.896V25.841 c0-0.493-0.402-0.895-0.896-0.895H18.619z"></path></g><g id="XMLID_892_"><path fill="#231F20"  d="M31.947,37.981c-0.2,0-0.4-0.054-0.577-0.163l-15.323-9.444c-0.517-0.319-0.678-0.997-0.359-1.514 c0.319-0.518,0.997-0.678,1.514-0.359l14.746,9.089l14.852-9.15c0.515-0.319,1.193-0.158,1.514,0.359 c0.318,0.518,0.157,1.195-0.359,1.514l-15.429,9.505C32.347,37.928,32.147,37.981,31.947,37.981z"></path></g></svg>
    `;
}
