/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconRadioSelected extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "RadioSelectedIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xml:space="preserve"><g><g><path fill="#231F20"  d="M32,47.476c-8.533,0-15.476-6.942-15.476-15.476S23.467,16.524,32,16.524S47.476,23.467,47.476,32 S40.533,47.476,32,47.476z M32,18.724c-7.32,0-13.276,5.956-13.276,13.276S24.68,45.276,32,45.276S45.276,39.32,45.276,32 S39.32,18.724,32,18.724z"></path></g><g><circle fill="#231F20"  cx="32" cy="32" r="10.864"></circle></g></g></svg>
    `;
}
