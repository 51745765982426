/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconCar extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "CarIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' x="0px" y="0px" viewBox="0 0 64 64" ><g transform="matrix(2.2,0,0,2.2,-1470.24,-816.31001)"><path d="m 692.8,388.9 v -2.8 c 0,-0.8 -0.3,-1.5 -0.8,-2.1 -0.3,-0.3 -0.6,-0.5 -0.9,-0.6 0,-0.1 0,-0.1 0,-0.2 l -2,-5.1 c -0.2,-0.4 -0.6,-0.7 -1,-0.7 h -9.8 c -0.5,0 -0.9,0.3 -1,0.7 l -2,5.1 c 0,0.1 0,0.1 0,0.2 -0.3,0.1 -0.6,0.3 -0.9,0.6 -0.6,0.6 -0.8,1.3 -0.8,2.1 v 2.8 c 0,0.7 0.3,1.8 0.8,2.4 0.3,0.3 0.6,0.5 1,0.7 v 1.2 c 0,0.8 0.7,1.5 1.5,1.5 0.8,0 1.5,-0.7 1.5,-1.5 v -1 h 9.6 v 1 c 0,0.8 0.7,1.5 1.5,1.5 0.8,0 1.5,-0.7 1.5,-1.5 V 392 c 0.4,-0.1 0.7,-0.4 1,-0.7 0.5,-0.6 0.8,-1.7 0.8,-2.4 z m -17,0.8 c -0.1,-0.1 -0.2,-0.5 -0.2,-0.8 v -2.8 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.1,-0.1 0.3,-0.2 0.5,-0.2 H 690 c 0.2,0 0.3,0.1 0.5,0.2 0.1,0.1 0.2,0.3 0.2,0.5 v 2.8 c 0,0 0,0 0,0 0,0.3 -0.1,0.7 -0.2,0.8 -0.1,0.1 -0.3,0.2 -0.5,0.2 h -13.5 c -0.4,0 -0.6,-0.1 -0.7,-0.2 z m 3.1,-10.1 h 8.3 l 1.4,3.6 h -11.1 z" id="path3682" fill="#231f20"></path><circle cx="677.90002" cy="387.60001" r="0.89999998" id="circle3684" fill="#231f20"></circle><circle cx="688.29999" cy="387.60001" r="0.89999998" id="circle3686" fill="#231f20"></circle></g></svg>
    `;
}
