/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconWebsite extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "WebsiteIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xml:space="preserve"><path fill="#231F20"  d="M25.374,43.92c-1.471,0-2.85-0.569-3.884-1.604c-1.034-1.033-1.604-2.412-1.604-3.884 c0-1.471,0.57-2.85,1.604-3.884l3.687-3.688c0.43-0.43,1.126-0.43,1.556,0s0.43,1.126,0,1.556l-3.687,3.687 c-0.619,0.619-0.959,1.446-0.959,2.329c0,0.884,0.341,1.71,0.959,2.328c1.237,1.238,3.42,1.237,4.657,0.001l4.687-4.688 c1.185-1.185,1.292-3.066,0.25-4.378c-0.378-0.476-0.299-1.167,0.177-1.545c0.476-0.377,1.169-0.297,1.545,0.177 c1.738,2.188,1.56,5.327-0.417,7.302l-4.687,4.687C28.224,43.351,26.844,43.92,25.374,43.92z"></path></g><g><path fill="#231F20"  d="M30.32,33.994c-0.317,0-0.631-0.137-0.849-0.399c-1.783-2.159-1.628-5.401,0.352-7.381l4.687-4.688 c1.035-1.034,2.414-1.604,3.885-1.604s2.851,0.569,3.885,1.604s1.604,2.414,1.604,3.884s-0.569,2.85-1.604,3.884l-3.686,3.688 c-0.43,0.428-1.126,0.428-1.556,0c-0.43-0.429-0.43-1.126-0.001-1.556l3.687-3.688c0.619-0.619,0.96-1.446,0.96-2.329 s-0.341-1.71-0.959-2.329c-1.238-1.236-3.42-1.238-4.658,0l-4.687,4.688c-1.187,1.187-1.28,3.13-0.211,4.425 c0.387,0.468,0.321,1.162-0.148,1.548C30.814,33.911,30.566,33.994,30.32,33.994z"></path></svg>
    `;
}
