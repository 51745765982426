/* $FlowIgnore */

export default [
    "domestic violence",
    "family violence",
    "elder abuse",
    "sexual abuse",
    "sexual violence",
    "child abuse",
    "violence",
    "abuse",
    "assault",
    "rape",
    "incest",
];
