/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconPencil extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "PencilIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewBox="0 0 143.76239 143.76239"><g transform="translate(-24.839506,-44.53932)"><g stroke="#000000" fill="none" fill="none" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" transform="matrix(0.26458333,0,0,0.26458333,16.962035,37.42813)"><path id="rect2990" d="M 70.064,422.35 444.334,48.09 551.914,155.67 177.654,529.94 48.094,551.91 Z" stroke-width="30" fill="none" fill-opacity="1" stroke="#ffffff" stroke-opacity="1"></path><path id="path3771" d="m 70.569,417.81 110.61,110.61" stroke-width="25" fill="none" fill-opacity="1" stroke="#ffffff" stroke-opacity="1"></path><path id="path3777" d="M 491.47,108.37 124.78,475.05" stroke-width="25" fill="none" fill-opacity="1" stroke="#ffffff" stroke-opacity="1"></path><path id="path3763" d="m 54.222,507.26 40.975,39.546" stroke-width="25" fill="none" fill-opacity="1" stroke="#ffffff" stroke-opacity="1"></path></g></g></svg>
    `;
}
