/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconTickColor extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "TickColorIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon points="30.07 40.92 46.61 24.37 43.17 20.93 27.92 36.19 20.61 29.64 17.39 33.29 25.88 41.02 27.92 43.07 28.02 42.96 28.13 43.07 30.07 40.92" fill="#48b7b9"></polygon></svg>
    `;
}
