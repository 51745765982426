/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconLegal extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "LegalIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g><rect width="64" height="64" fill="none"></rect><path d="M36.28,55.86,35.41,21.4a7.18,7.18,0,0,0-.33-13.22l-.14-5.4h-5l-.14,5.4a7.18,7.18,0,0,0-.33,13.22l-.87,34.46L12.84,58.74v2.15H52V58.74L36.28,55.86" fill="#5c696d"></path><path d="M43.36,42l7-20.24L57.4,42h-14M37.78,9.61h-.44a7.15,7.15,0,0,1,2.22,4.31A17.78,17.78,0,0,1,45.22,16a25.59,25.59,0,0,0,3.1,1.8L40.06,42H38V44c1.65,5,6.61,8.26,12.39,8.26S61.12,49,62.77,44V42H60.71L52.66,19.32a24.56,24.56,0,0,0,3.92.66V15.84c-5.16-.59-7.43-1.88-9.5-3.36a24.43,24.43,0,0,0-8.46-2.83,7.36,7.36,0,0,0-.84,0M7.44,37.86l7-20.24,7,20.24h-14M24.21,8.12a21.62,21.62,0,0,0-6.44,1,15.86,15.86,0,0,1-6.55,1.36,25.87,25.87,0,0,1-2.94-.18v4.13a29.19,29.19,0,0,0,3.29.26l.63,0L4.14,37.86H2.08v2.06c1.65,5,6.61,8.26,12.39,8.26s10.74-3.3,12.39-8.26V37.86H24.79L16.53,14.14a19.7,19.7,0,0,0,3.1-1.09,14.66,14.66,0,0,1,4.92-.78c.39,0,.77,0,1.15,0a7.22,7.22,0,0,1,2.8-3.46,7.66,7.66,0,0,0-2.26-.62,17.8,17.8,0,0,0-2-.11" fill="#b3adb0"></path><path d="M32.42,4.81s-1.24,2.34-2.48,4.88a8.39,8.39,0,0,0-1.46-.84,7.22,7.22,0,0,0-2.8,3.46l.54.05c.83.09,1.65.92,2.27,1.33a4,4,0,0,0-.21,1.45,4.13,4.13,0,1,0,8.26,0,4,4,0,0,0-.21-1.45,1.15,1.15,0,0,1,.66-.18c.49,0,1.05.21,1.61.27.31,0,.62.08.94.14a7.15,7.15,0,0,0-2.22-4.31,6.49,6.49,0,0,0-2.44.64c-1.24-2.83-2.48-5.45-2.48-5.45" fill="#b3adb0"></path></g></svg>
    `;
}
