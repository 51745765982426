/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconCrossColor extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "CrossColorIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon points="43.51 23.73 39.77 20.15 32.01 28.27 24.23 20.15 20.49 23.73 28.42 32 20.49 40.27 24.23 43.85 32.01 35.73 39.77 43.85 43.51 40.27 35.58 32 43.51 23.73" fill="#f48267"></polygon></svg>
    `;
}
