/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconClock extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "ClockIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' x="0px" y="0px" viewBox="0 0 64 64" ><g transform="matrix(2.1311038,0,0,2.1311038,-37.623755,-33.241564)"><path fill="#231f20" d="m 33.071,20.487 c -5.511,0 -9.995,4.481 -9.995,9.991 0,5.509 4.484,9.992 9.995,9.992 5.511,0 9.994,-4.482 9.994,-9.992 0,-5.51 -4.483,-9.991 -9.994,-9.991 z m 0,17.97 c -4.401,0 -7.982,-3.58 -7.982,-7.979 0,-4.399 3.581,-7.978 7.982,-7.978 4.4,0 7.981,3.579 7.981,7.978 0,4.399 -3.58,7.979 -7.981,7.979 z m 3.8,-6.721 -2.793,-1.806 -0.001,-4.994 c 0,-0.556 -0.451,-1.006 -1.006,-1.006 -0.556,0 -1.007,0.451 -1.007,1.006 l 10e-4,5.542 c 0,0.342 0.173,0.659 0.46,0.845 l 3.253,2.104 c 0.169,0.109 0.358,0.161 0.545,0.161 0.33,0 0.654,-0.162 0.846,-0.46 0.303,-0.467 0.169,-1.09 -0.298,-1.392 z"></path></g></svg>
    `;
}
