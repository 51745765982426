/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconDemographicAboriginal extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "DemographicAboriginalIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xml:space="preserve"><title>Aboriginal flag.</title><g><path fill="#ED2B2C"  d="M58.359,27.494c-3.327-1.478-6.713-2.197-10.607-2.197c-5.487,0-10.901,1.447-16.198,2.846 c-5.111,1.35-10.365,2.746-15.462,2.747c-3.466,0-6.562-0.634-9.499-1.938c-0.462-0.206-1.241-0.164-1.668,0.113 C4.499,29.341,4,29.814,4,30.322v17.156c0,0.593,0.599,1.13,1.141,1.371c3.327,1.478,6.962,2.196,10.857,2.196 c5.487,0,11.027-1.446,16.323-2.846c5.111-1.351,10.428-2.747,15.525-2.747c3.466,0,6.594,0.635,9.528,1.938 c0.465,0.204,0.759,0.163,1.185-0.113c0.426-0.277,0.44-0.75,0.44-1.258V28.865C59,28.272,58.9,27.735,58.359,27.494z"></path><path fill="#231F20"  d="M58.359,10.396C55.032,8.918,51.646,8.2,47.752,8.2c-5.487,0-10.901,1.447-16.198,2.846 c-5.111,1.35-10.365,2.746-15.462,2.747c-3.466,0-6.562-0.634-9.499-1.938c-0.462-0.206-1.241-0.164-1.668,0.113 C4.499,12.244,4,12.717,4,13.225v16.195c0,0.593,0.599,1.13,1.141,1.371c3.327,1.479,6.963,2.197,10.857,2.197 c5.487,0,11.026-1.447,16.323-2.846c5.111-1.35,10.428-2.747,15.524-2.747c3.467,0,6.594,0.634,9.529,1.938 c0.465,0.207,0.759,0.163,1.185-0.113c0.426-0.277,0.44-0.75,0.44-1.258V11.767C59,11.174,58.9,10.637,58.359,10.396z"></path><path fill="#FDD900"  d="M34.224,20.968c-0.77,0-1.562,0.111-2.356,0.331c-5.198,1.434-9.27,6.481-9.271,11.491 c0,4.41,3.02,7.61,7.18,7.61c0.769,0,1.562-0.111,2.356-0.33c5.198-1.436,9.271-6.482,9.271-11.491 C41.403,24.168,38.384,20.968,34.224,20.968z"></path></g></svg>
    `;
}
