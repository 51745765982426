/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconEntertainment extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "EntertainmentIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><polyline points="12.78 33.87 35.1 18.48 47.7 21.65 53.76 35.84 29.9 51.74 12.78 33.87" fill="#c77600"></polyline><path d="M40.54,44.65l-5.21,3.47,1.06,1.06a3,3,0,0,0,4.34,0,3,3,0,0,0,0-4.34l-.19-.19m6.52-4.34-5.21,3.47,1.06,1.06a3,3,0,0,0,4.34,0,3,3,0,0,0,0-4.34l-.19-.19m6.6-4.4-5.3,3.53a3,3,0,0,0,4.31,0,3,3,0,0,0,1-3.5M2.1,8.22V33l9.77,1.66,18,18.82a3,3,0,0,0,4.34,0,3,3,0,0,0,0-4.34L34.06,49l0,0L29.9,51.74,12.78,33.86,35.1,18.48l2,.49-1.6-1.6,3.88-2.95-3.1-3.1H15.29L2.1,8.22" fill="#ffc571"></path><path d="M35.1,18.48,12.78,33.86,29.9,51.74,34,49a.75.75,0,0,1,0-1,.75.75,0,0,1,1.09,0l.18.18,5.21-3.47-.28-.28a.75.75,0,0,1,0-1.09.75.75,0,0,1,1.09,0l.49.49,5.21-3.47L46.78,40a.75.75,0,0,1,0-1.09.75.75,0,0,1,1.09,0l.47.47,0,0,5.3-3.53a3.09,3.09,0,0,0-.67-1L37.06,19l-2-.49" fill="#f4aa3e"></path><path d="M38.4,20.48l-7.29,2.79a11,11,0,0,1-12.26-2.79,1.35,1.35,0,0,1,.46-2.17L40,8.22a7.43,7.43,0,0,1,7.6.62l.62.47a7.59,7.59,0,0,0,6.05,1.09l8.52-2.17V33L55,34.6l-1.24,1.24Z" fill="#c77600"></path><path d="M24.47,52.82h0a3,3,0,0,0,4.34,0l1.09-1.09a3.07,3.07,0,0,0-4.34-4.34l-1.09,1.09A3,3,0,0,0,24.47,52.82Z" fill="#c77600"></path><path d="M20,48.48h0a3,3,0,0,0,4.34,0L25.4,47.4a3.07,3.07,0,0,0-4.34-4.34L20,44.14A3.3,3.3,0,0,0,20,48.48Z" fill="#c77600"></path><path d="M15.62,44.14h0a3,3,0,0,0,4.34,0l1.09-1.09a3.07,3.07,0,0,0-4.34-4.34l-1.09,1.09A3,3,0,0,0,15.62,44.14Z" fill="#c77600"></path><path d="M11.28,39.64h0a3,3,0,0,0,4.34,0l1.09-1.09a3.07,3.07,0,1,0-4.34-4.34l-1.09,1.09A3,3,0,0,0,11.28,39.64Z" fill="#c77600"></path></g></svg>
    `;
}
