/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconTick extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "TickIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  xml:space="preserve"><path fill="#231F20"  d="M25.481,39.924c-0.281,0-0.563-0.107-0.777-0.322l-7.308-7.308c-0.43-0.429-0.43-1.126,0-1.555 c0.428-0.43,1.127-0.43,1.555,0l6.53,6.529L42.64,20.11c0.428-0.43,1.127-0.43,1.555,0c0.43,0.429,0.43,1.126,0,1.555 L26.259,39.601C26.045,39.816,25.762,39.924,25.481,39.924z"></path></svg>
    `;
}
