/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconSkills extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "SkillsIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M57.45,18.59a2.48,2.48,0,0,1-3,.07L53,17.16h0a3,3,0,0,0,.51-1.67,2.92,2.92,0,0,0-.82-2.06L48.88,9.5l-.1-.1L48,8.57,44.41,4.86c-3.33-3.47-15.5-3.61-16.88-3.61H26.46V3.7l.69.21C27.34,4,31,5.08,35.5,10c2,2.19,4.11,5.09,2.7,6.54,0,0-.2.22-.82.87l-.13.18L37,18.69h0L3.76,49a2.46,2.46,0,0,0,0,3.47L11,59.92a2.46,2.46,0,0,0,3.57-.06L42.93,26.77h0l2.71-1.93,0,0,1.13-1.18h0l1.41,1.46a2.32,2.32,0,0,1-.38,3.11h0L54,34.68l9.6-9.9-6.2-6.2" fill="#698a99"></path><path d="M37.75,32.81,28.66,43.42,44.53,59.3l13.31,3.45L54.39,49.44,37.75,32.81M9.21,7.15a7,7,0,0,0-4.93,2h0a7,7,0,0,0,0,9.85l15.4,15.4L30,25.06,14.14,9.19a7,7,0,0,0-4.93-2" fill="#f77268"></path><polyline points="30 25.06 19.69 34.45 28.66 43.42 37.75 32.81 30 25.06" fill="#d1554f"></polyline></g></svg>
    `;
}
