/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconMental extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "MentalIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><g><rect height="64" fill="none" width="64"></rect><path d="M25.55,58.36,25,52.2l-3.42,1A6.51,6.51,0,0,1,13.39,48L12,39.4,8,38.11a2.37,2.37,0,0,1-1.4-3.31l4.29-8.69c0-10.12,4.91-20.49,19.67-21.7C45.74,3.17,54,13.79,55,25.18a21.67,21.67,0,0,1-3.4,13.15A19.32,19.32,0,0,0,49,50l.53,6.44Z" fill="#ffcc80"></path><path d="M28.51,33.74a12.13,12.13,0,0,1,1.06-4.12,8.2,8.2,0,0,1,2.52-2.67,11.24,11.24,0,0,0,2.39-2.1A3.7,3.7,0,0,0,35.22,23q.34-3-2.42-3.32a2.79,2.79,0,0,0-2.18.58,5.35,5.35,0,0,0-1.15,2.85l-5.12-.57c.27-2.27,1.28-4.66,2.85-5.79a8.5,8.5,0,0,1,6.07-1.27A8.52,8.52,0,0,1,38.88,18a6.57,6.57,0,0,1,1.49,5.39,6.4,6.4,0,0,1-1,2.79,11.89,11.89,0,0,1-2.68,2.73l-1.58,1.21A4.94,4.94,0,0,0,33.24,33L33,34.24Z" fill="#9c27b0"></path><path d="M27.89,39.06a2.63,2.63,0,0,1,1-1.83,2.8,2.8,0,0,1,3.81.42,2.64,2.64,0,0,1,.53,2,2.67,2.67,0,1,1-5.3-.59Z" fill="#9c27b0"></path></g></g></svg>
    `;
}
