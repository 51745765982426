/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconSupport extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "SupportIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M55,19.4a3.82,3.82,0,0,0-7.43-1.74l-3.63,14.4-.21-.47L48,12a3.82,3.82,0,0,0-7.43-1.74L35.72,29.71l-.2,0L40.41,7.31A3.82,3.82,0,0,0,33,5.57l-5.53,22.2-.08,0L31.51,8.23a4,4,0,0,0-2.85-4.57,4.05,4.05,0,0,0-4.58,2.83c-.29,1.24-8.16,33.06-8.16,33.06l-4.71-6.92a4.38,4.38,0,0,0-6.7-1.3A5,5,0,0,0,3.39,38L9.06,49c3.19,5.62,9.27,8.49,13.5,9.48L32,60.69a13.08,13.08,0,0,0,15.79-9.56s-.25,1.76,1.68-6.5L55,19.4" fill="#ffcc80"></path><path d="M43.81,31.81l.11.25,0-.14-.15-.11M53,28.53,49.48,44.64c-1.35,5.79-1.64,6.66-1.68,6.66s0-.16,0-.16a12.59,12.59,0,0,1-1.4,3.51L47,55l.53.34.42-.47a32.73,32.73,0,0,1,2.59-2.44c4.15-3.69,10.34-9.33,9.32-16.76A8.43,8.43,0,0,0,53,28.53" fill="#f886c1"></path><path d="M51.45,28.39a8.58,8.58,0,0,0-1.16.08,8.43,8.43,0,0,0-5.95,3.8l-.4-.33,0,.14-.11-.25a8.45,8.45,0,0,0-13.46,7.84c1,7.5,8.54,11.21,13.46,13.66q1.49.7,2.58,1.33a12.59,12.59,0,0,0,1.4-3.51s0,.16,0,.16.33-.86,1.68-6.66L53,28.53a8.45,8.45,0,0,0-1.54-.14" fill="#f8728e"></path></g></svg>
    `;
}
