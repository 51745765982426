/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconTram extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "TramIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" id="svg3717" ><g transform="matrix(1.98,0,0,1.98,-32.401152,-30.84844)"><g id="g3713"><path id="XMLID_664_" fill="#231f20" d="m 41.326,24.312 c 0,-1.568 -1.182,-2.842 -2.634,-2.842 H 27.435 c -1.452,0 -2.633,1.275 -2.631,2.776 l -0.743,11.448 c -0.051,0.781 0.227,1.557 0.762,2.128 0.535,0.571 1.291,0.899 2.073,0.899 h 0.062 l -0.782,1.173 c -0.314,0.47 -0.187,1.106 0.284,1.42 0.174,0.116 0.372,0.172 0.566,0.172 0.331,0 0.656,-0.16 0.852,-0.456 l 1.539,-2.309 h 7.292 l 1.538,2.309 c 0.197,0.296 0.522,0.456 0.852,0.456 0.195,0 0.392,-0.056 0.566,-0.172 0.471,-0.314 0.597,-0.95 0.284,-1.42 l -0.782,-1.173 h 0.062 c 0.782,0 1.539,-0.328 2.073,-0.899 0.535,-0.57 0.812,-1.345 0.762,-2.127 V 35.694 Z M 27.435,23.517 h 11.257 c 0.319,0 0.587,0.364 0.589,0.861 l 0.386,5.941 H 26.459 l 0.389,-6.007 c 0,-0.431 0.269,-0.795 0.587,-0.795 z m 12.377,12.906 c -0.15,0.159 -0.362,0.251 -0.581,0.251 H 26.896 c -0.219,0 -0.431,-0.092 -0.58,-0.251 -0.15,-0.16 -0.228,-0.377 -0.213,-0.596 l 0.224,-3.46 H 39.8 l 0.225,3.46 c 0.014,0.22 -0.064,0.437 -0.213,0.596 z"></path><path id="XMLID_662_" fill="#231f20" d="m 28.365,33.543 c -0.499,0 -0.903,0.404 -0.903,0.904 0,0.499 0.404,0.903 0.903,0.903 0.499,0 0.903,-0.404 0.903,-0.903 0,-0.5 -0.404,-0.904 -0.903,-0.904 z"></path><path id="XMLID_660_" fill="#231f20" d="m 37.763,33.543 c -0.499,0 -0.903,0.404 -0.903,0.904 0,0.499 0.404,0.903 0.903,0.903 0.499,0 0.903,-0.404 0.903,-0.903 -10e-4,-0.5 -0.405,-0.904 -0.903,-0.904 z" inkscape:connector-curvature="0"></path></g></g></svg>
    `;
}
