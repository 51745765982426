/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconHealth extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "HealthIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><rect height="64" fill="none" width="64"></rect><path d="M28.36,52l-.79-.51a48.2,48.2,0,0,0-4.64-2.44C15.69,45.45,4.6,40,3.09,28.92A12.46,12.46,0,0,1,23.74,18a12.46,12.46,0,0,1,22.81,5C48,34,38.93,42.29,32.8,47.73A48.07,48.07,0,0,0,29,51.32l-.62.7" fill="#f44336"></path><path d="M58.35,24.49,41,39.44a57.34,57.34,0,0,1-5.87,6.15l5,5L61.59,27.73l-3.24-3.24" fill="#4ccb76"></path><path d="M34.19,32.13l-6.24,6.24,6,6,1.21,1.21A57.34,57.34,0,0,0,41,39.44l-.37.32-6.49-7.63" fill="#49933a"></path></svg>
    `;
}
