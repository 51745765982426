/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconWarning extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "WarningIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M43 16L50.5 12.5L57.5 16L93.5 78.5L87.5 87H11.5L7.5 78.5L43 16Z" fill="#DFCC1E"></path><path d="M93.6103 74.9503L76.2503 44.8703L58.8803 14.8003C57.9791 13.2431 56.6842 11.9503 55.1256 11.0516C53.567 10.1528 51.7995 9.67969 50.0003 9.67969C48.2011 9.67969 46.4336 10.1528 44.875 11.0516C43.3164 11.9503 42.0215 13.2431 41.1203 14.8003L23.7503 44.8703L6.3903 74.9503C5.49251 76.5094 5.02046 78.2771 5.02149 80.0762C5.02251 81.8753 5.49658 83.6424 6.39615 85.2005C7.29572 86.7585 8.58915 88.0526 10.1467 88.953C11.7043 89.8534 13.4712 90.3284 15.2703 90.3303H84.7303C86.5294 90.3284 88.2963 89.8534 89.8539 88.953C91.4114 88.0526 92.7049 86.7585 93.6044 85.2005C94.504 83.6424 94.9781 81.8753 94.9791 80.0762C94.9801 78.2771 94.5081 76.5094 93.6103 74.9503V74.9503ZM89.2803 82.7003C88.827 83.5061 88.1656 84.1753 87.3652 84.638C86.5648 85.1006 85.6548 85.3397 84.7303 85.3303H15.2703C14.3489 85.3284 13.4442 85.0844 12.6467 84.6229C11.8492 84.1614 11.1869 83.4985 10.7262 82.7005C10.2654 81.9026 10.0224 80.9976 10.0214 80.0762C10.0203 79.1548 10.2613 78.2493 10.7203 77.4503L28.0803 47.3703L45.4503 17.3003C45.9127 16.5032 46.5763 15.8415 47.3749 15.3815C48.1734 14.9216 49.0788 14.6795 50.0003 14.6795C50.9218 14.6795 51.8272 14.9216 52.6257 15.3815C53.4243 15.8415 54.0879 16.5032 54.5503 17.3003L71.9203 47.3703L89.2803 77.4503C89.7501 78.2453 89.9979 79.1519 89.9979 80.0753C89.9979 80.9988 89.7501 81.9053 89.2803 82.7003Z" fill="black"></path><path d="M53.2602 69.4805H46.7402V76.0005H53.2602V69.4805Z" fill="black"></path><path d="M44.3398 42.9303L46.7798 66.0603H53.2198L55.6598 42.9303V38.0703H44.3398V42.9303Z" fill="black"></path></svg>
    `;
}
