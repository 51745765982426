/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconDemographicDisability extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "DemographicDisabilityIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M48,51.8,43.93,38.16H38.34a13.56,13.56,0,0,0-16.33-6l-3.95-15.8H8.81v2.73h7.12l3.57,14.26a13.63,13.63,0,1,0,20.06,7.57H41.9L46,54.53h9.2V51.8H48" fill="#455a64"></path><path d="M25.18,16.33c-5.46,0-4.09,5.46-4.09,5.46l2.7,9.84a13.32,13.32,0,0,1,2.76-.29,13.64,13.64,0,0,1,11.8,6.82h5.59L48,51.8h4.44L48.92,35.43s-.66-2.73-3.27-2.73H35l-.38-1.37h5.81a1.62,1.62,0,0,0,1.7-1.85,1.91,1.91,0,0,0-1.88-2.09H33.55L32,21.78s-1.36-5.45-6.82-5.46" fill="#6ea3dc"></path><path d="M26.54,31.33a13.32,13.32,0,0,0-2.76.29l.81,3a4.84,4.84,0,0,0,4.67,3.56H42.92L47,51.8h1L43.93,38.16H38.34a13.64,13.64,0,0,0-11.8-6.82" fill="#4982bd"></path><path d="M23.81,4a5.46,5.46,0,1,0,5.46,5.46A5.46,5.46,0,0,0,23.81,4" fill="#6ea3dc"></path></g></svg>
    `;
}
