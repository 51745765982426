/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconAboriginalFlag extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "AboriginalFlagIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 6"><path d="M0,0h10v6H0z"></path><path d="M0,3h10v3H0z" fill="#c00"></path><circle cx="5" cy="3" r="1.5" fill="#ff0"></circle></svg>
    `;
}
