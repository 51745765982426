/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconDemographicRecentlyArrived extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "DemographicRecentlyArrivedIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M53.84,35.75C51.65,41.25,50.56,50,43.66,50.11c-3.53.07-2.94-1.92-5-3.3-3.38-2.22,1.09-1.57-4.21-1.49-.07-1.83,1.79-3.34,1.74-5.22a5.15,5.15,0,0,1-2.95,2.64c-2.81-1.56-2.76-3.46-6.69-3.62s-4.67,1.39-7.63,2.52c-1,.39-7.53,2.84-8.2,2.61-2.62-.88-.57-2.86-.84-4.87-.49-3.54-4.54-10-3.36-13.48.41-1.23,10.21-8.38,10.67-8.19C18.43,17,18.38,15,20,14.36c2-.81,2.62,1.29,4.54.84-.47-.52,1.25-4.82,1.59-4.82.41-.21,7.39.33,8.38,1.29,1,2-4.87,2.05,1.23,5.69,6.69,4,3-4.4,5.51-8.54a45.33,45.33,0,0,1,3.33,5.56c1.37,2.84,1.85,5,3.85,7.56C51.13,25.4,56.09,30.43,53.84,35.75Zm-9,16.67c-.8.35-3-.7-3.55-.33-1.14.83,1.69,5,1.89,5.06,2.54.59,2.83-5.13,3-5.13A1.34,1.34,0,0,1,44.86,52.41Z" fill="#66c150"></path><path d="M41.27,26.39l-2.72-4.61-7.86,13.9,16-.14-2.53-4.3c7.64-6.34,16.76-1.22,17-1C60.78,29.93,55.1,19.68,41.27,26.39Z" fill="#455a64"></path></g></svg>
    `;
}
