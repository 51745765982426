/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconChat extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "ChatIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewBox="0 0 17 17.707108" ><g fill="#36c" id="g1647" fill="none" stroke="#ffffff" stroke-opacity="1" transform="translate(0.5,0.5)"><path d="M 14,0 H 2 A 2,2 0 0 0 0,2 v 14 l 4,-4 h 10 a 2,2 0 0 0 2,-2 V 2 A 2,2 0 0 0 14,0 Z" id="path1645" fill="none" stroke="#ffffff" stroke-opacity="1"></path></g></svg>
    `;
}
