/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconFood extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "FoodIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><rect height="64" fill="none" width="64"></rect><path d="M29.65,39.19l-4,4-3-3.08L9.83,51.76a4.53,4.53,0,0,0,0,6.56,4.53,4.53,0,0,0,6.56,0L31.93,41.48l-2.29-2.29M51.48,5.53A17.7,17.7,0,0,0,39.24,11c-5.28,5.27-6.8,12.59-4.15,17.75l-2.17,2,4.66,4.66,2-2.15a12,12,0,0,0,5.56,1.29,17.65,17.65,0,0,0,12.22-5.43c6.25-6.25,7.26-15.37,2.26-20.36a11.28,11.28,0,0,0-8.12-3.18" fill="#c0c8cc"></path><path d="M37.58,35.36l-5.65,6.12L48.79,58.33a4.06,4.06,0,0,0,5.88,0,4.06,4.06,0,0,0,0-5.88L37.58,35.36M7,4.85,5.37,6.3a11.59,11.59,0,0,0,0,16.32L22.62,40.1l10.3-9.4L16,13.78,7,4.85" fill="#ffd366"></path><polyline points="32.92 30.7 22.62 40.09 25.66 43.17 29.65 39.19 31.93 41.48 37.58 35.35 32.92 30.7" fill="#c0a552"></polyline></svg>
    `;
}
