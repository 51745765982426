/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconTech extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "TechIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M10.15,54,2.78,15.68A4.89,4.89,0,0,1,6.66,10L25.82,6.29a4.89,4.89,0,0,1,5.71,3.87L38.9,48.49A4.89,4.89,0,0,1,35,54.2L15.86,57.88A4.89,4.89,0,0,1,10.15,54" fill="#37474f"></path><path d="M26.79,9.85l-.28,0L7.35,13.56a1.15,1.15,0,0,0-1,1.43l6.67,34.74a1.14,1.14,0,0,0,1.15,1l.28,0L33.65,47a1.15,1.15,0,0,0,1-1.43L32.11,32.53l-2.58-2,1.76-2.32L27.94,10.85a1.14,1.14,0,0,0-1.15-1" fill="#5c696d"></path><path d="M36.19,21.81l-1.93,2.54,2.21,11.51,1.82,1.38a11,11,0,0,0-2.1-15.43" fill="#a6e8f0"></path><polyline points="34.26 24.34 31.29 28.26 32.11 32.53 36.47 35.85 34.26 24.34" fill="#6ab1bc"></polyline><polyline points="31.29 28.26 29.52 30.57 32.11 32.53 31.29 28.26" fill="#75bbc5"></polyline><path d="M42.18,13.92,38.85,18.3A15.41,15.41,0,0,1,41.8,39.9l4.38,3.33a20.92,20.92,0,0,0-4-29.31" fill="#a6e8f0"></path><path d="M48.18,6l-3.33,4.38a25.32,25.32,0,0,1,4.84,35.48l4.38,3.33A30.83,30.83,0,0,0,48.18,6" fill="#a6e8f0"></path></g></svg>
    `;
}
