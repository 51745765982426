/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconUsingViolence extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "UsingViolenceIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g><rect width="64" height="64" fill="none"></rect><path d="M60.07,27.81a4.36,4.36,0,0,0-4.28-4.41H52.84a4.42,4.42,0,0,0,0-8.83h-18L41,12.8,37.26,9.16c-1.19-1.2-2.42-2-3.94-1.94a6.18,6.18,0,0,0-4.14,1.42l-5.66,4c-1.61,1.61-3.06,3-4.25,4.48h-1a14.35,14.35,0,0,0,0,28.7h1.6a13.22,13.22,0,0,0,9.52,4.06H42.29l6.14,0A4.42,4.42,0,0,0,49,41h3.89a4.42,4.42,0,0,0,.71-8.76h2.1l-.26-.07h.39a4.36,4.36,0,0,0,4.28-4.41" fill="#ffcc80"></path><path d="M45.43,49.85l-3.14,0H33l-3,4.26,3.82,2.67,4.41-6.3,6.29,4.4,2.67-3.82-1.73-1.21" fill="#f6695e"></path><path d="M41.42,37.76,37.05,44,30.8,39.62l-2.67,3.82,6.26,4.37-1.43,2h9.33l3.14,0-4.56-3.19,4.36-6.24-3.82-2.67" fill="#f65e45"></path></g></svg>
    `;
}
