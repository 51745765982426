/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconHouse extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "HouseIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><rect height="64" fill="none" width="64"></rect><polyline points="57.6 28.44 6.4 28.44 6.4 61.7 39.2 61.7 57.6 61.7 57.6 28.44" fill="#b0deea"></polyline><polyline points="32 2.31 2.03 28.28 2.03 28.44 5.36 28.44 6.4 28.44 6.4 24.73 57.6 27.93 57.6 28.44 58.64 28.44 61.97 28.44 32 2.31" fill="#cc6145"></polyline><polyline points="6.4 24.73 6.4 28.44 57.6 28.44 57.6 27.93 6.4 24.73" fill="#bc5b42"></polyline></svg>
    `;
}
