/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconAdvocacy extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "AdvocacyIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><path d="M45,9.68,19.86,8.06A12.62,12.62,0,0,0,6.46,19.84L5.58,33.46A12.61,12.61,0,0,0,16.75,46.8l-1,10.72,14.66-9.81,12.09.78A12.62,12.62,0,0,0,55.93,36.71l.88-13.62A12.62,12.62,0,0,0,45,9.68Z" fill="#9cd6e5"></path><path d="M27.26,18.87a1.15,1.15,0,0,1,1-1.48l2.29-.23a1.18,1.18,0,0,1,1.29,1.25l.2,13.1A1.17,1.17,0,0,1,31,32.77a1.15,1.15,0,0,1-1.26-1ZM31.19,35.1a2.19,2.19,0,0,1,2.48,2l0,.32a2.27,2.27,0,0,1-4.52.46l0-.32A2.21,2.21,0,0,1,31.19,35.1Z" fill="#37474f"></path></g></svg>
    `;
}
