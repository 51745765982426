/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconUnder18 extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "Under18Icon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g><rect width="64" height="64" fill="none"></rect><circle cx="32" cy="32" r="27.76" fill="#43a047"></circle><path d="M14,33.1a2,2,0,0,1,2-2h4.77a2,2,0,0,1,0,4H16A2,2,0,0,1,14,33.1Z" fill="#fff"></path><path d="M27.37,26.41l-1.48.38a3.45,3.45,0,0,1-.74.11,1.85,1.85,0,0,1-1.84-1.81,1.87,1.87,0,0,1,1.4-1.81l2.71-.79a7.52,7.52,0,0,1,2-.36h.06a2.08,2.08,0,0,1,2.08,2.08V39.68a2.08,2.08,0,0,1-4.17,0Z" fill="#fff"></path><path d="M34.61,36.33v-.05a4.66,4.66,0,0,1,3.1-4.63,4.56,4.56,0,0,1-2.44-4.19V27.4c0-2.93,2.69-5.26,6.74-5.26s6.75,2.3,6.75,5.26v.06a4.57,4.57,0,0,1-2.44,4.19c1.95,1,3.1,2.25,3.1,4.52v.06c0,3.56-3.15,5.65-7.4,5.65S34.61,39.68,34.61,36.33ZM45.33,36v-.05c0-1.48-1.4-2.36-3.32-2.36s-3.32.88-3.32,2.36V36c0,1.32,1.23,2.38,3.32,2.38S45.33,37.35,45.33,36Zm-.58-8.09v-.06c0-1.18-1-2.25-2.74-2.25s-2.74,1-2.74,2.22v.05A2.45,2.45,0,0,0,42,30.25C43.68,30.25,44.75,29.26,44.75,27.92Z" fill="#fff"></path></g></svg>
    `;
}
