/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconMoney extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "MoneyIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><polyline points="61.85 38.75 6.23 47.38 1.2 14.93 56.82 6.31 61.85 38.75" fill="#c9d7bf"></polyline><path d="M35.75,18.57a20.52,20.52,0,1,0,20.51,21q0-.26,0-.53A20.52,20.52,0,0,0,35.75,18.57Z" fill="#fff"></path><rect height="64" fill="none" width="64"></rect><path d="M39.88,42.16l-2.72.42v3.1c1.84-.22,2.92-1.19,2.92-2.48a2.55,2.55,0,0,0-.19-1" fill="#69b36b"></path><path d="M37.15,40.39v2.19l2.72-.42c-.33-.75-1.12-1.31-2.72-1.77" fill="#5da14c"></path><path d="M34.88,42.94l-4.35.67,0,0a11.14,11.14,0,0,0,4.32,1.94V42.94m21.38-3.31-12.9,2a6.13,6.13,0,0,1,.17,1.47c0,3.24-2.48,5.29-6.26,5.72v1.73a1.3,1.3,0,1,1-2.59,0V48.6a11.81,11.81,0,0,1-5.94-2.27,1.47,1.47,0,0,1-.76-1.4,1.65,1.65,0,0,1,.31-1L16.35,45.81a20.52,20.52,0,0,0,39.91-6.19" fill="#69b36b"></path><path d="M35.75,18.57A20.53,20.53,0,0,0,16.35,45.81L28.28,44a1.58,1.58,0,0,1,1.31-.65,1.72,1.72,0,0,1,.94.3l4.35-.67V39.85c-4.21-1.08-6.26-2.7-6.26-6,0-3.13,2.48-5.29,6.16-5.62v-.65c-.11-.54.54-1.19,1.19-1.19a1.31,1.31,0,0,1,1.3,1.3v.65a10.88,10.88,0,0,1,4.54,1.62,1.5,1.5,0,0,1,.86,1.4A1.66,1.66,0,0,1,41,32.94a2.55,2.55,0,0,1-.86-.22,9.14,9.14,0,0,0-3-1.3V37c3.69.9,5.65,2.32,6.2,4.58l12.9-2q0-.26,0-.53A20.52,20.52,0,0,0,35.75,18.57" fill="#5da14c"></path><path d="M34.88,31.1c-1.84.22-2.81,1.19-2.81,2.48s.54,2.05,2.81,2.7V31.1" fill="#5da14c"></path></g></svg>
    `;
}
