/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconDemographicVeteran extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "DemographicVeteranIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewbox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g><rect height="64" fill="none" width="64"></rect><rect height="8" rx="1.33" ry="1.33" fill="#f7c90b" transform="translate(8.27 50.13) rotate(-83.45)" width="4" x="30.24" y="16.43"></rect><polygon points="48.88 39.12 48.88 39.12 36.72 35.93 31.86 23.75 24.37 34.51 11.8 34.86 19.61 44.7 15.84 58.13 28.52 52.88 39.67 60.87 39.04 46.93 48.88 39.12" fill="#f7c90b"></polygon><polyline points="48.71 5.55 43.41 4.94 36.79 4.18 31.49 3.57 24.87 2.81 19.57 2.2 18.81 8.82 23.57 14.14 23.57 14.14 29.52 20.79 34.81 21.4 42.11 16.27 42.11 16.27 47.95 12.17 48.71 5.55" fill="#f44336"></polyline><path d="M34.14,3.87l2.65.3,6.62.76,5.3.61-.55,4.75,3.49.4.54-4.75L34.14,3.87M22.6,2.55l2.27.26,3.93.45-6.2-.71M16.08,1.8l-.55,4.75L19,7l.55-4.75-3.49-.4" fill="#fadf6d"></path><polyline points="19.57 2.2 19.03 6.95 48.17 10.3 48.71 5.55 43.41 4.94 36.79 4.18 34.14 3.88 28.8 3.26 24.87 2.81 22.6 2.55 19.57 2.2" fill="#f6931c"></polyline></g></svg>
    `;
}
