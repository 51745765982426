/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconQuestionMarkColor extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "QuestionMarkColorIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M29.46,40.41V37.24a4.41,4.41,0,0,1,1.49-3.78L33,31.62c2-1.84,3.08-3.16,3.08-5.32a3.78,3.78,0,0,0-3.7-3.86h-.32A3.75,3.75,0,0,0,28,25.8v.37H23c0-5.24,4.09-8.12,9-8.12s9.14,2.9,9.14,8.44c0,3.52-2.16,6-4.47,8.21L35.37,36A3.52,3.52,0,0,0,34,39v1.43Zm2.25,3.13h0a2.58,2.58,0,0,0-2.58,2.58h0A2.58,2.58,0,0,0,31.7,48.7h0a2.58,2.58,0,0,0,2.58-2.58h0a2.58,2.58,0,0,0-2.58-2.58Z" fill="#a64ab6"></path></svg>
    `;
}
