/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconAccessibility extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "AccessibilityIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' viewBox="0 0 64 64" ><path fill="#363e43" d="m 26.345562,16.253307 a 4.3731757,4.3731757 0 1 0 -4.468938,-4.362535 4.4178651,4.4178651 0 0 0 4.468938,4.362535 z m 27.505254,28.941699 a 1.8258806,1.8258806 0 0 0 -2.39833,-0.787385 L 49.245681,45.492935 42.938093,35.02285 v 0 a 2.6749789,2.6749789 0 0 0 -2.294055,-1.27684 h -9.829537 v -3.511308 h 7.146046 a 1.745015,1.745015 0 1 0 0,-3.49003 h -7.146046 v -4.362534 a 4.4689387,4.4689387 0 0 0 -8.937877,0 v 4.617903 a 14.896462,14.896462 0 1 0 17.875754,14.598533 14.362318,14.362318 0 0 0 -0.26388,-2.617521 h 0.336235 l 7.214144,11.661801 6.009658,-3.106976 a 1.7216054,1.7216054 0 0 0 0.804409,-2.340872 z M 35.632443,41.619855 A 10.614793,10.614793 0 0 1 24.566499,52.45171 10.614793,10.614793 0 0 1 13.500555,41.619855 10.401987,10.401987 0 0 1 21.893648,31.085929 v 3.532587 a 4.4178651,4.4178651 0 0 0 4.468939,4.362537 h 8.976182 a 11.170219,11.170219 0 0 1 0.295801,2.638802 z" id="path3686" fill-rule="evenodd" stroke-width="2.12806582"></path></svg>
    `;
}
